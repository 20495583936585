const styles = {
    primary: "#ffff00",

    gBlue: {
        top: "red",
        bottom: "yellow"
    },

    retroBlack: "#3B3B3B",
    midGray: "#adadad",

    mobileWidth: 940,
    padWidth: 1500
};

export default styles;
